body {
  /* font-family: 'Ubuntu', sans-serif; */
  /* font-family: 'Raleway', sans-serif; */
  font-family: 'Josefin Sans', sans-serif;

  font-weight:500;
}  
.btnLoader{
  width: 110px;
}
.mdlFooter {
    background: #fff;
    bottom: 0;
    box-shadow: -1px 1px 9px #838c96;
    left: -2px;
    padding: 7px;
    position: fixed;
    width: 100%;
}
.modal-content{
    overflow: auto;
}
.modal-body{
    margin: 0px 0px 60px 0px;
}
.modal-header{
z-index: 999;
}
/* width */
::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #348cd4; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #348cd4; 
  }
  .legendFontsize{
    font-size: unset;
    font-size: 13px;
  
    color: #fa6767;
  }
  .table-full-width-td  th {
    white-space: nowrap;
  }
  .table-full-width-td  td {
    white-space: nowrap;
  }
  .select2{
    position: relative;
    top: 36px;
    display: none;
  }
  .form-control[readonly] {
    background-color: #f6f6f6;
}
table .dropdown {
  top: 3px;
} 
.centerCss{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    /* background-color: #000; */
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
    top: 45%;
}